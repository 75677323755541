import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Link } from 'react-router-dom';

// Enhanced wave animation for letters
const wave = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-2px);
  }
  75% {
    transform: translateY(2px);
  }
`;

// Enhanced glow pulse
const glowPulse = keyframes`
  0%, 100% {
    box-shadow: 0 0 10px rgba(76, 140, 47, 0.5),
                0 0 20px rgba(76, 140, 47, 0.3),
                0 0 30px rgba(76, 140, 47, 0.2);
  }
  50% {
    box-shadow: 0 0 15px rgba(76, 140, 47, 0.7),
                0 0 25px rgba(76, 140, 47, 0.5),
                0 0 35px rgba(76, 140, 47, 0.3);
  }
`;

// Button hover animation
const hoverScale = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  padding: 4px;
  z-index: 1;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg, 
      rgba(76, 140, 47, 0.8), 
      rgba(106, 180, 62, 0.8), 
      rgba(76, 140, 47, 0.8)
    );
    border-radius: 25px;
    padding: 4px;
    animation: ${glowPulse} 2s infinite;
    z-index: -1;
    filter: blur(4px);
  }
`;

const animateLetters = (text) => {
  return text.split('').map((letter, i) => css`
    &:nth-child(${i + 1}) {
      animation: ${wave} 1s ease-in-out infinite;
      animation-delay: ${i * 0.05}s;
    }
  `);
};

const Letter = styled.span`
  display: inline-block;
  ${props => animateLetters(props.text)}
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
`;

const StyledButton = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.7em 1.4em;
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(0.9rem, 1.5vw + 0.3rem, 1.2rem);
  font-weight: bold;
  color: white;
  background: linear-gradient(135deg, #4c8c2f, #6ab43e);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
  max-width: 260px; // Reduced max-width for desktop
  width: fit-content;

  &:hover {
    animation: ${hoverScale} 0.6s ease infinite;
    background: linear-gradient(135deg, #6ab43e, #4c8c2f);

    ${Letter} {
      animation-duration: 0.8s; // Faster wave animation on hover
    }
  }

  &:active {
    transform: scale(0.97);
  }

  @media (max-width: 768px) {
    padding: 0.6em 1.2em;
    border-radius: 20px;
    font-size: clamp(0.8rem, 1.2vw + 0.3rem, 1.1rem);
  }

  @media (max-width: 480px) {
    padding: 0.5em 1em;
    border-radius: 18px;
    font-size: clamp(0.8rem, 1vw + 0.3rem, 1rem);
  }
`;

const FartIcon = styled.div`
  position: relative;
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
  transition: transform 0.3s ease;
  
  &::before {
    content: '';
    position: absolute;
    font-size: 1em;
    transition: all 0.3s ease;
  }
`;

const GooeyButton = () => {
  const [buttonText] = useState("Start Farting Now!");

  return (
    <ButtonWrapper>
      <StyledButton to="/game">
        {buttonText.split('').map((letter, index) => (
          <Letter key={index} text={buttonText}>
            {letter}
          </Letter>
        ))}
        <FartIcon />
      </StyledButton>
    </ButtonWrapper>
  );
};

export default GooeyButton;