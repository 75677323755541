// PartnersSection.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import partnerLogo1 from '../assets/plogos/ps.png';
import partnerLogo2 from '../assets/plogos/ds.png';
import partnerLogo3 from '../assets/plogos/dv.png';
import partnerLogo4 from '../assets/plogos/dt.png';
import partnerLogo6 from '../assets/plogos/cmc.png';
import partnerLogo7 from '../assets/plogos/coingecko.webp';
import partnerLogo8 from '../assets/plogos/solscan.webp';
import SectionTitle from './SectionTitle';


const moveAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const PartnersSectionContainer = styled.section`
  padding: 60px 0;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 11%);
    background-size: 60px 60px;
    opacity: 0.8;
    z-index: 1;
    pointer-events: none;
  }
`;

const PartnersSectionTitleContainer = styled.div`
  text-align: center;
`;




const PartnersLogoStrip = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  animation: ${moveAnimation} 30s linear infinite;
`;

const PartnerLogo = styled.img`
  width: 200px;
  height: auto;
  margin: 0 40px;
  filter: grayscale(100%);
  transition: filter 0.3s ease;

  &:hover {
    filter: grayscale(0%);
  }
`;

const PartnersOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 11%);
  background-size: 80px 80px;
  opacity: 0.6;
  z-index: 2;
  pointer-events: none;
  animation: ${moveAnimation} 20s linear infinite reverse;
`;




const FartParticles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;
`;

const FartParticle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  opacity: 0.8;
  animation: ${moveAnimation} 5s linear infinite;

  &:nth-child(1) {
    top: 20%;
    left: 10%;
    animation-delay: 0.5s;
  }

  &:nth-child(2) {
    top: 40%;
    left: 30%;
    animation-delay: 1s;
  }

  &:nth-child(3) {
    top: 60%;
    left: 50%;
    animation-delay: 1.5s;
  }

  &:nth-child(4) {
    top: 80%;
    left: 70%;
    animation-delay: 2s;
  }

  &:nth-child(5) {
    top: 10%;
    left: 90%;
    animation-delay: 2.5s;
  }
`;

const PartnersSection = () => {
  return (
    <React.Fragment>
    <PartnersSectionContainer>
      <PartnersSectionTitleContainer>
      <SectionTitle>Our Fart-nerships</SectionTitle>
      </PartnersSectionTitleContainer>
    
      <PartnersLogoStrip>
        <PartnerLogo src={partnerLogo1} alt="Partner Logo 1" />
        <PartnerLogo src={partnerLogo2} alt="Partner Logo 2" />
        <PartnerLogo src={partnerLogo3} alt="Partner Logo 3" />
        <PartnerLogo src={partnerLogo7} alt="Partner Logo 4" />
        <PartnerLogo src={partnerLogo8} alt="Partner Logo 1" />
        <PartnerLogo src={partnerLogo3} alt="Partner Logo 3" />
        <PartnerLogo src={partnerLogo4} alt="Partner Logo 4" />
        <PartnerLogo src={partnerLogo6} alt="Partner Logo 5" />
      </PartnersLogoStrip>
      <PartnersOverlay />
     
   
      <FartParticles>
        {Array.from({ length: 5 }).map((_, index) => (
          <FartParticle key={index} />
        ))}
      </FartParticles>
    </PartnersSectionContainer>
    </React.Fragment>
  );
};

export default PartnersSection;
