import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import logoVariation from '../assets/aboutlogo.webp';
import welcomeD from '../assets/welcomeDM.mp4';
import welcomeM from '../assets/welcomeMM.mp4';
import SectionTitle from './SectionTitle';
import SectionDescription from './SectionDec';

const AboutContainer = styled.section`
  padding: 60px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  animation: fadeIn 1s ease-in-out;

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const AboutContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;



const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-top: 30.80%; /* 16:9 aspect ratio */
  background-color: #000;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 40px;
    padding-top: 56.25%; 
  }
`;



const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 60px;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 40px;
    order: 2;
  }
`;

const LogoVariationContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  animation: floatAnimation 3s ease-in-out infinite;

  @keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    max-width: 300px;
    margin-top: 40px;
  }
`;

const LogoVariation = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 60px;

  @media (max-width: 768px) {
    padding-left: 0;
    align-items: center;
    text-align: center;
  }
`;

const LogoVariationOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    opacity: 1;
  }
`;

const LogoVariationText = styled.p`
  font-family: 'Exo 2', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${LogoVariationOverlay}:hover & {
    opacity: 1;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const FartCloudLeft = styled.div`
  position: absolute;
  top: 20%;
  left: -100px;
  width: 200px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
  animation: floatAnimation 4s ease-in-out infinite;
  z-index: -1;

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
    top: 10%;
    left: -50px;
  }
`;

const FartCloudRight = styled.div`
  position: absolute;
  bottom: 20%;
  right: -100px;
  width: 250px;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
  animation: floatAnimation 5s ease-in-out infinite reverse;
  z-index: -1;

  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
    bottom: 10%;
    right: -50px;
  }
`;
const PlayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    top: 50%;
    transform: translateY(-50%);
  }
`;
const About = () => {
  const isMobile = window.innerWidth <= 768;

  return (
    <React.Fragment>
    <AboutContainer id='about'>
      <AboutContent>
        <LeftColumn>
          <LogoVariationContainer>
            <LogoVariation src={logoVariation} alt="Logo Variation" />
            <LogoVariationOverlay>
              <LogoVariationText>Frens I am to the moon</LogoVariationText>
            </LogoVariationOverlay>
          </LogoVariationContainer>
        </LeftColumn>
        <RightColumn>
          <SectionTitle>About $FARTI</SectionTitle>
          <SectionDescription textalign="left">
          We believe laughter is the best medicine. Our mission is to spread joy and humor throughout the crypto space by rewarding gassy contributions and offering a range of fart-themed activities.
          </SectionDescription>
          <SectionDescription textalign="left">
          In FartiLand, you can play the Farti game, time your toots with passing cars, and earn Fart Points, which can be exchanged for $FARTI tokens. 
          </SectionDescription>
          <SectionDescription textalign="left">
          You can also upload your best farts to our Telegram Chat, where daily votes determine the top toots, which are rewarded on a daily basis. 
          </SectionDescription>
          <SectionDescription textalign="left">
          Every game and every vote supports IBS research through partnerships with health organizations across all 6 continents.
          </SectionDescription>
        </RightColumn>
      </AboutContent>
      <FartCloudLeft />
      <FartCloudRight />
    </AboutContainer>
    </React.Fragment>
  );
};

export default About;


