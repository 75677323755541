import styled, { keyframes } from 'styled-components';

const fartAnimation = keyframes`
  0% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(-10px); }
  100% { transform: translateX(-50%) translateY(0); }
`;

const SectionTitle = styled.h2`
contain: content;
  will-change: contents;
  font-family: 'Exo 2', sans-serif;
  font-size: 48px;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 60px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  letter-spacing: 4px;
  line-height: 1.2;
  position: relative;
  z-index: 2;
  white-space: nowrap;
  display: inline-block;

  &::before {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 48px;
    color: rgba(255, 255, 255, 0.6);
    animation: ${fartAnimation} 2s infinite;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, #8b4513, transparent);
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    font-size: 40px;
    margin-bottom: 50px;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.6), 0 0 16px rgba(76, 140, 47, 0.3), 3px 3px 0 #8b4513;
    -webkit-text-stroke: 1.5px #4c8c2f;

    &::before {
      font-size: 40px;
      top: -30px;
    }
  }

  @media (max-width: 768px) {
    font-size: 32px;
    margin-bottom: 40px;
    text-shadow: 0 0 6px rgba(255, 255, 255, 0.4), 0 0 12px rgba(76, 140, 47, 0.2), 2px 2px 0 #8b4513;
    -webkit-text-stroke: 1px #4c8c2f;

    &::before {
      font-size: 32px;
      top: -20px;
    }
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 30px;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.2), 0 0 8px rgba(76, 140, 47, 0.1), 1px 1px 0 #8b4513;
    -webkit-text-stroke: 0.5px #4c8c2f;

    &::before {
      font-size: 24px;
      top: -10px;
    }
  }
`;

export default SectionTitle;


