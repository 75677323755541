// CTASection.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import logoVariation from '../assets/lovelogo.webp';
import SectionTitle from './SectionTitle';
import SectionDescription from './SectionDec';
import { HowToBuyButton } from '../components/common/Button';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CTASectionContainer = styled.section`
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  animation: ${fadeIn} 1s ease-in-out;

  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`;

const CTAContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CTAText = styled.div`
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;






const LogoVariation = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-top: 60px;
  animation: floatAnimation 3s ease-in-out infinite;

  @keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    max-width: 300px;
    margin-top: 40px;
  }
`;

const CTASection = () => {
  return (
    <React.Fragment>
    <CTASectionContainer>
      <CTAContent>
        <CTAText>
          <SectionTitle>Join the Fart Revolution!</SectionTitle>
          <SectionDescription>
          Don’t miss out on the opportunity to be a part of the most hilarious and unique meme project ever created. 
<br/>
Buy $FARTI now and let your farts be heard across the blockchain.
          </SectionDescription>
        </CTAText>
        <HowToBuyButton>Buy $FARTI Now!</HowToBuyButton>
        <LogoVariation src={logoVariation} alt="Logo Variation" />
      </CTAContent>
    </CTASectionContainer>
    </React.Fragment>
  );
};

export default CTASection;
