// src/pages/GamePage.js
import React, { Suspense, lazy } from 'react';
import styled from 'styled-components';

const GameSection = lazy(() => import('../components/GameSection'));

const GamePageContainer = styled.div`
  padding: 20px;
`;

const LoadingText = styled.h2`
  text-align: center;
  color: #ffffff;
`;


const GamePage = () => {
  return (
    <GamePageContainer>
        
      <Suspense fallback={<LoadingText>Loading Farti Game...</LoadingText>}>
        <GameSection />
      </Suspense>
    </GamePageContainer>
  );
};

export default GamePage;
