// GameSection.js
import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const GameSectionContainer = styled.section`
  padding: 100px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  animation: ${fadeIn} 1s ease-in-out;

  @media (max-width: 768px) {
    padding: 80px 20px;
  }
`;

const GameSectionTitle = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 48px;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 30px;
  }
`;

const GameSectionDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  position: relative;
  z-index: 2;
  background: linear-gradient(to right, #ffffff, #c0c0c0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 30px;
    max-width: 90%;
  }
`;

const GameContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 562px; // 16:9 aspect ratio for 1000px width
  position: relative;
  margin: 0 auto;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    max-width: 100%;
    height: 56.25vw; // Maintain 16:9 aspect ratio on smaller screens
    max-height: 75vh; // Limit height on very tall/narrow screens
  }
`;

const GameCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Loader = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
`;

const Fart = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background: #4c8c2f;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background: #8b4513;
    border-radius: 50%;
  }
`;

const LoaderText = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: 36px;
  font-weight: 800;
  color: #4c8c2f;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 0 #8b4513;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); // Semi-transparent dark background
  z-index: 10;
`;

const GameSection = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadUnityGame = async () => {
      try {
        const loaderScript = document.createElement('script');
        loaderScript.src = '/Build/Build/WebBuild.loader.js';
        loaderScript.async = true;
        document.body.appendChild(loaderScript);

        loaderScript.onload = async () => {
          if (typeof window.createUnityInstance === "undefined") {
            return;
          }

          const unityInstance = await window.createUnityInstance(canvasRef.current, {
            dataUrl: "/Build/Build/WebBuild.data.unityweb",
            frameworkUrl: "/Build/Build/WebBuild.framework.js.unityweb",
            codeUrl: "/Build/Build/WebBuild.wasm.unityweb",
            streamingAssetsUrl: "StreamingAssets",
            companyName: "DefaultCompany",
            productName: "FartiLand",
            productVersion: "0.1",
          }, (progress) => {
            if (progress === 1) {
              setIsLoading(false);
            }
          });
        };

        loaderScript.onerror = () => {
          setIsLoading(false);
        };
      } catch (error) {
        setIsLoading(false);
      }
    };

    loadUnityGame();

    return () => {
      const loaderScript = document.querySelector('script[src="/Build/Build/WebBuild.loader.js"]');
      if (loaderScript) {
        document.body.removeChild(loaderScript);
      }
    };
  }, []);

  return (
    <GameSectionContainer id='game-section'>
      <GameSectionTitle>PLAY FARTING GAME</GameSectionTitle>
      <GameSectionDescription>
      Dive into the fun of FartiLand with our interactive Farting game! 

      </GameSectionDescription>
     
      <GameSectionDescription>
      Fart  with the sound of passing cars, read the instructions on  how to play before starting the game. 

      </GameSectionDescription>
   
      
      <GameContainer ref={containerRef}>
        {isLoading && (
          <LoaderContainer>
            <Loader>
              <Fart />
            </Loader>
            <LoaderText>Loading FartiLand...</LoaderText>
          </LoaderContainer>
        )}
        <div id="unity-container" style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
          <GameCanvas ref={canvasRef} id="unity-canvas" />
        </div>
      </GameContainer>
    </GameSectionContainer>
  );
};

export default GameSection;