import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/logoHeaderwhite.webp';
import { Link, useLocation } from 'react-router-dom';
import GooeyButton from './common/GooeyButton';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 999;
  animation: ${fadeIn} 1s ease;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    flex-wrap: nowrap;
    background-color: #3a6b24;
    backdrop-filter: none;
  }
`;

const Logo = styled.img`
  height: 130px;
  width: auto;
  cursor: pointer;
  margin-right: 20px;

  @media (max-width: 768px) {
    height: 50px;
    margin-right: 10px;
  }
`;

const GameNavContainer = styled(NavbarContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GameNavLogo = styled(Logo)`
  height: 60px;
  margin: 0 auto; 
`;

const GameNavButton = styled(Link)`
  font-family: 'Exo 2', sans-serif;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`;

const GameNavExternalButton = styled.a`
  font-family: 'Exo 2', sans-serif;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`;

const MenuItems = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;

  @media (max-width: 768px) {
    position: fixed;
    top: 70px; // Adjusted to match new header height
    right: 0;
    width: 100%;
    height: auto; // Changed from 100vh to auto
    flex-direction: column;
    align-items: center;
    background-color: #3a6b24;
    padding: 20px;
    transform: ${props => (props.isopen ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const MenuItem = styled.li`
  margin-left: 30px;
  cursor: pointer;
  font-family: 'Exo 2', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
  position: relative;
  background: linear-gradient(to right, #ffffff, #c0c0c0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &:hover {
    color: #ffcc00;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffcc00;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  @media (max-width: 768px) {
    margin: 12px 0;
    font-size: 18px;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 16px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin-right: 10px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    display: flex;
  }
`;

const HamburgerLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: relative;
 
  &:nth-child(1) {
    transform: ${props => (props.isopen ? 'rotate(45deg) translate(5px, 5px)' : 'none')};
  }

  &:nth-child(2) {
    opacity: ${props => (props.isopen ? '0' : '1')};
  }

  &:nth-child(3) {
    transform: ${props => (props.isopen ? 'rotate(-45deg) translate(5px, -5px)' : 'none')};
  }
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsMenuOpen(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  if (location.pathname === '/game') {
    return (
      <GameNavContainer>
        <GameNavButton to="/" backgroundcolor="#4c8c2f">
          BACK TO FARTI
        </GameNavButton>
        <GameNavLogo src={logo} alt="FARTI LAND Logo" />
        <GameNavExternalButton 
          href="https:/" 
          target="_blank" 
          rel="noopener noreferrer" 
          backgroundcolor="#8b4513"
        >
          Buy $FARTI
        </GameNavExternalButton>
      </GameNavContainer>
    );
  }

  return (
    <NavbarContainer>
      <Logo src={logo} alt="FARTI LAND Logo" />
      <MenuItems isopen={isMenuOpen ? 'true' : undefined}>
        <MenuItem onClick={() => handleMenuItemClick('about')}>About</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('hall-of-fame')}>Hall of Fame</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('roadmap')}>Roadmap</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('tokenomics')}>Tokenomics</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('how-to-buy')}>How to Buy</MenuItem>
      </MenuItems>
      <GooeyButton />
      <HamburgerMenu onClick={handleMenuToggle}>
        <HamburgerLine isopen={isMenuOpen ? 'true' : undefined} />
        <HamburgerLine isopen={isMenuOpen ? 'true' : undefined} />
        <HamburgerLine isopen={isMenuOpen ? 'true' : undefined} />
      </HamburgerMenu>
    </NavbarContainer>
  );
};

export default Navbar;

