// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import MainBackground from './components/Background';
import HomePage from './pages/HomePage';
import GamePage from './pages/gamePage';



const AppContainer = styled.div`
  position: relative;
  z-index: 1;
`;

function App() {
  return (
    <Router>
      <MainBackground />
      <AppContainer>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/game" element={<GamePage />} />
        </Routes>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
